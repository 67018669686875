@import "../../../../../../assets/styles/helpers/variables";
.content{
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    @media #{$media-ipad} {
      display: unset;
      margin-top: 0;
    }
    .itemRight{
      width: 45%;
      margin-bottom: 50px;
      @media #{$media-ipad} {
        width: 100%;
        margin-top: 50px;
      }
      h2{
        margin-bottom:15px ;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-transform: capitalize;
        color: #000000;

      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: #000000;
      }

    }
  }
  //&Left, &Right {
  //  width: 45%;
  //  .item{
  //    h2{
  //      margin-bottom:15px ;
  //      font-weight: 700;
  //      font-size: 20px;
  //      line-height: 23px;
  //      text-transform: capitalize;
  //      color: #000000;
  //
  //    }
  //    p{
  //      font-style: normal;
  //      font-weight: 400;
  //      font-size: 16px;
  //      line-height: 180%;
  //      color: #000000;
  //    }
  //  }
  //  @media #{$media-ipad} {
  //    width: 100%;
  //
  //  }
  //}
}
//.item{
//  margin-top: 50px;
//}