@import "../../../../../assets/styles/helpers/variables";

.framework {
  margin-top: 70px;
  background: #FCF7EF;

  .main {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 68px;
    padding-bottom: 5.4655%;
    @media #{$media-ipad} {
      flex-direction: column;
      padding: 30px 0;
      align-items: center;
    }

    .content {
      width: 39.296875%;
      @media #{$media-ipad} {
        width: 100%;
        padding: 0 20px;
      }

      h2 {
        font-weight: 700;
        font-size: 34px;
        line-height: 1.25;
        text-transform: capitalize;
        color: #333333;
        padding-bottom: 10px;
        @media #{$media-ipad} {
          margin: 0;
          font-size: 24px;
          padding-bottom: 12px;
        }
      }

      p {
        font-size: 16px;
        color: #333333;
        line-height: 1.75;
        padding: 10px 0;
        text-align: justify;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }
    }

    .image {
      width: 51.25%;
      @media #{$media-ipad} {
        width: calc(100% - 40px);
        margin-top: 50px;
        margin-bottom: 28px;
      }
    }

    .img {
      padding-top: 33.8414634146%;
      width: 100%;
      @media #{$media-ipad} {
        padding-top: 33.7628865979%;
      }
    }
  }
}