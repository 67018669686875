@import "../../../../assets/styles/helpers/variables";

.footer {
  width: 100%;
  height: auto;
  background: #CCE0FF;
  padding: 20px 0;
  margin-top: 8.934%;
  @media #{$media-ipad} {
    margin-top: 98px;
  }

  .main {
    .mainContent {
      padding: 20px 0 38px;
      width: 100%;
      @include display-flex;
      justify-content: space-between;
      @media #{$media-ipad} {
        flex-wrap: wrap;
      }

      .itemFirst {
        @media #{$media-ipad} {
          margin: 0 0 11.5px !important;
          width: 100% !important;
        }

        .listIcon {
          margin-top: 34px;
          @include display-flex;
          @media #{$media-ipad} {
            display: none;
          }
        }

        .description {
          font-size: 14px;
          line-height: 1.7;
          color: #333333;
        }
      }

      .item {
        width: 17.734375%;
        @media #{$media-ipad} {
          width: calc(50% - 7px);
          margin: 16.5px 0;
          overflow: hidden;
        }

        &Label {
          font-weight: 700;
          font-size: 14px;
          line-height: 1.5;
          color: #333333;
          margin-bottom: 17.5px;
          @media #{$media-ipad} {
            margin-bottom: 15px;
          }
        }

        &Value {
          font-size: 14px;
          line-height: 1.7;
          color: #333333;
          padding: 2.5px 0;
          transition: 0.3s;
          cursor: pointer;
          @media #{$media-ipad} {
            padding: 5px 0;
            line-height: 1.5;
          }

          &:hover {
            text-decoration: underline;
            transition: 0.3s;
          }

          &Text {
            cursor: default;

            &:hover {
              text-decoration: none;
            }
          }

          &Link {
            @media #{$media-ipad} {
              @include display-flex;
              flex-direction: column;
            }
          }
        }

        .img {
          width: 100%;
          padding-top: 22.41379310344828%;
          background-size: contain;

          &Container {
            max-width: 89.86784140969163%;
            width: 100%;
            margin-bottom: 25px;
            @media #{$media-ipad} {
              max-width: 51.03092783505155%;
            }
          }
        }
      }
    }

    .mainCopyright {
      border-top: 1px solid #00237D;
      padding: 23px 0 3px;
      @media #{$media-ipad} {
        padding-top: 19px;
        padding-bottom: 27px;
      }

      p {
        font-size: 12px;
        line-height: 1.25;
        color: #333333;
      }
    }

    .listIcon {
      margin-top: 7px;
      display: none;
      @media #{$media-ipad} {
        @include display-flex;
        margin-top: 0;
      }

      .icon {
        width: 25px;
        height: 25px;
        margin-right: 17px;
        cursor: pointer;
      }
    }
  }
}