@import "../../../assets/styles/helpers/variables";

.container {
  padding-top: 67px;
  @media #{$media-ipad} {
   padding-top: 41px;
  }

  &Introduce {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    @media #{$media-ipad} {
      display: block;
    }
  }

  &Left {
    width: 39.296875%;

    .title {
      font-size: 34px;
      font-weight: 700;
      line-height: 1.25;
      @media #{$media-ipad} {
        font-size: 24px;
      }
    }

    .content {
      padding-top: 22px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.75;
      text-align: justify;
      span{
        color: #3E95FC;
      }
      @media #{$media-ipad} {
        font-size: 14px;
      }
    }
  }

  &Right {
    width: 39.6875%;
    @media #{$media-ipad} {
      margin-top: 40px;
    }

    .image {
      width: 100%;
      padding-top: 83.8582677165%;
      background-size: contain;
    }
  }

  &Item {
    @media #{$media-ipad} {
      padding: 0;
      width: 100%;
    }
  }
}