@import "../../../../../assets/styles/helpers/variables";

.container {
  position: relative;
  margin-top: 8.934%;
  @media #{$media-ipad} {
    margin: 98px 0 0;
  }

  &Content {
    padding-top: 138px;
    padding-bottom: 126px;
    @media #{$media-ipad} {
      padding-top: 28px;
      padding-bottom: 36px;
    }

    .itemContainer {
      margin-top: 84px;
      @include display-flex;
      flex-wrap: wrap;
      @media #{$media-ipad} {
        margin-top: 36px;
      }

      .imageContainer {
        margin-top: 15px;
        margin-right: 30px;
        width: 17.734375%;
        background-color: #FFFFFF;
        @include display-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: relative;
        transition: 0.3s;
        @media #{$media-ipad} {
          width: 37.11340206185567%;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        }

        &:hover {
          &:after {
            content: '';
            position: absolute;
            background-color: $main-color;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: 0.3s;
            bottom: -12px;
            right: -12px;
            box-shadow: 0 0 10px $main-color;
          }
        }

        .logoContainer {
          padding: 26px 42px 0;
          width: 100%;
          @media #{$media-ipad} {
            padding: 12px 27px 0;
          }

          .logo {
            width: 100%;
            padding-top: 67.6056338028169%;
            background-size: contain;
            @media #{$media-ipad} {
              padding-top: 67.77777777777778%;
            }
          }
        }

        .partnersName {
          padding-top: 22px;
          padding-bottom: 12px;
          font-size: 14px;
          line-height: 1.75;
          @media #{$media-ipad} {
            font-size: 12px;
            padding-top: 13px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .bgImage {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
  }
}