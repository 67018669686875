@import "../../../../../assets/styles/helpers/variables";

.containerShowcase {
  margin-top: 90px;
  @media #{$media-ipad} {
    margin-top: 45px;
    padding: 0;
  }

  .title {
    @media #{$media-ipad} {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }

  .showcaseMain {
    .action {
      @include display-flex;
      margin-top: 62px;
      overflow-x: auto;
      @media #{$media-ipad} {
        margin-top: 38px;
        padding: 0 20px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .first {
        margin-left: 15px;
      }

      .btn {
        white-space: nowrap;
        font-size: 16px;
        color: #555555;
        padding: 10px 20px;
        background: #F5F5F5;
        border: none;
        border-radius: 5px;
        margin-right: 15px;
        transition: 0.3s;
        cursor: pointer;
      }

      .activeBtn {
        background-color: $main-color;
        color: #FFFFFF;
        transition: 0.3s;
      }
    }

    .grid {
      margin: 65px -20px 100px;
      @include display-flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media #{$media-ipad} {
        margin: 39px 13px 100px;
        justify-content: space-between;

      }
    }
  }
}