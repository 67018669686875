@import "../../../../../../assets/styles/helpers/variables";

.backgroundContainer {
  width: 100%;
  background-size: contain;
  background-position: center;
  margin-top: 218px;
  @media #{$media-ipad} {
    background-position: center 27px;
    margin-top: 164px;
  }

  .container {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    @media #{$media-ipad} {
      flex-direction: column;
      justify-content: unset;
    }

    .imageContainer {
      width: 41.875%;
      @media #{$media-ipad} {
        width: 100%;
        padding: 0 22px;
      }

      .image {
        width: 100%;
        padding-top: 121.455223881%;
      }
    }

    .contentContainer {
      width: 51.484375%;
      @media #{$media-ipad} {
        width: 100%;
        margin-top: 58px;
        padding-top: unset;
      }

      .title {
        font-size: 34px;
        font-weight: 700;
        line-height: 1.35;
        color: #333333;
        @media #{$media-ipad} {
          font-size: 24px;
        }
      }

      .content {
        font-size: 16px;
        line-height: 1.75;
        padding-top: 13px;
        color: #333333;
        @media #{$media-ipad} {
          padding-top: 10px;
          font-size: 14px;
        }

        .paragraph {
          padding: 13px 0;
          text-align: justify;
          @media #{$media-ipad} {
            padding: 10px 0;
          }
        }
      }
    }
  }
}