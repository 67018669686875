@import "../../../assets/styles/helpers/variables";
.mainTitle {
  width: 100%;
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.25;
  color: #3E95FC;
  padding-bottom: 20px;
  background-image: linear-gradient(#3E95FC, #3E95FC);
  background-size: 120px 5px;
  background-position: bottom left;
  background-repeat: no-repeat;
  @media #{$media-ipad} {
    font-size: 24px;
  }
}
