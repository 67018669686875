@import "../../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 105px;
  padding: 0 2px;
  @media #{$media-ipad} {
    margin-top: 57px;
  }

  &Top {
    width: 70%;
    padding-left: 18px;
    @media #{$media-ipad} {
      width: 100%;
      padding: 0 18px;
    }

    .main {
      &Description {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
      }
    }
  }

  &Bottom {
    @include display-flex;
    flex-wrap: wrap;
    @media #{$media-ipad} {
      padding-top: 45.5px;
    }

    .itemContainer {
      @include display-flex;
      width: calc(50% - 36px);
      padding: 20px;
      margin: 18px;
      background-color: #CCE0FF;
      @media #{$media-ipad} {
        width: 100%;
        margin: 12.5px 18px;
        flex-direction: column;
        position: relative;
      }

      &:nth-child(2n) {
        background-color: #FFCCDD;
      }

      &:nth-child(3n) {
        background-color: #FCF7EF;
      }

      .item {
        &Info {
          padding-right: 21px;
          max-width: 66.49484536082474%;
          @media #{$media-ipad} {
            max-width: 100%;
          }
        }

        &Title {
          font-size: 20px;
          font-weight: 700;
          line-height: 1.2;
          @media #{$media-ipad} {
            font-size: 16px;
          }
        }

        &Content {
          padding-top: 15px;
          font-size: 14px;
          line-height: 1.75;
          text-align: justify;
          @media #{$media-ipad} {
            width: 100%;
            color: #333333;
          }
        }
      }

      .btnReadMore {
        padding-top: 10px;
        @include display-flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.75;
        color: #DB2017;
        cursor: pointer;
        @media #{$media-ipad} {
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-weight: 700;
        }

        img {
          margin-left: 16px;
          width: 22px;
          min-width: 22px;
          @media #{$media-ipad} {
            width: 23px;
            min-width: 23px;
          }
        }
      }

      .imageContainer {
        width: 33.50515463917526%;
        @include display-flex;
        align-items: flex-end;
        @media #{$media-ipad} {
          width: 55.17241379310345%;
          display: block;
          margin: 15px auto 50px;
        }

        .image {
          width: 100%;
          padding-top: 87.6923076923%;
          @media #{$media-ipad} {
            padding-top: 88.02083333333333%;
            background-size: contain;
          }
        }
      }
    }
  }
}