@import "../../../../../assets/styles/helpers/variables";

.list {
  margin-top: 74px;
  @media #{$media-ipad} {
    margin-top: 52px;
    &:first-child {
      margin-top: 34px;
    }
  }

  .listTitle {
    h2 {
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
      color: #333333;
    }
  }

  .listItem {
    @include display-flex;
    flex-wrap: wrap;
    padding-top: 30px;
    @media #{$media-ipad} {
      flex-wrap: wrap;
      padding: 16.5px 8px 0;
    }
    @media #{$media-small-phone} {
      padding-left: 0;
      padding-right: 0;
    }

    .item {
      width: 151px;
      min-width: 151px;
      margin-right: 29px;
      cursor: pointer;
      @media #{$media-ipad} {
        width: calc(100% / 3);
        min-width: calc(100% / 3);
        padding: 12.5px 12px;
        margin: 0;
      }
      @media #{$media-small-phone} {
        padding-left: 7px;
        padding-right: 7px;
      }

      &:hover {
        .content {
          text-decoration: underline;
        }
      }

      .imageContainer {
        width: 100%;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
        border: 1px solid #F5F5F5;
        padding-top: 100%;
      }

      .content {
        text-align: center;
        font-size: 16px;
        line-height: 1.75;
        padding-top: 20px;
        color: #555555;
        @media #{$media-ipad} {
          padding-top: 12px;
        }
      }
    }
  }
}