@import "../../../../assets/styles/helpers/variables";

.styleContainer {
  background-color: #FCF7EF;
  margin-top: 7.62%;
  padding-bottom: 59px;
}

.container {
  &Top, &Bottom {
    width: 100%;
  }

  &Top {
    padding-top: 45px;
  }

  &Bottom {
    @include display-flex;
    justify-content: space-between;
    margin-top: 97px;

    &Left {
      width: 20%;
      @include display-flex;
      flex-direction: column;
      padding-left: 5px;
      background-image: linear-gradient($sub-color, $sub-color);
      background-size: 5px 100%;
      background-position: left;
      background-repeat: no-repeat;

      .tab {
        padding: 20px;
        position: relative;

        .connectLine {
          position: absolute;
          height: 1px;
          width: 8%;
          top: 50%;
          bottom: 0;
          left: -1%;
          right: 0;
          z-index: 1;
          border-bottom: 1px $sub-color solid;
        }

        &Title {
          padding-left: 7px;
        }
      }

      .tab:hover {
        cursor: pointer;
        transition: all 0.3s;
      }

      .activeTab {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.25;
        color: $main-color;

        .connectLine {
          border-bottom: 3px $sub-color solid;
        }
      }

      .tabLinks {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.25;
        color: #999999;

        &:hover {
          color: #000000;
          transition: all 0.3s;
        }
      }
    }

    &Right {
      width: 80%;

      .contentContainer {
        display: none;

        &.active {
          @include display-flex;
          flex-direction: column;
          align-items: center;
        }

        .content {
          font-size: 16px;
          text-align: justify;
          line-height: 1.75;
        }

        .imageContainer {
          margin-top: 83px;
          max-width: 371px;
          width: 100%;

          .image {
            width: 100%;
            padding-top: 73.0458221024%;
            background-size: contain;
          }
        }
      }
    }
  }
}