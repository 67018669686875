@import "../../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 91px;
  @media #{$media-ipad} {
    margin-top: 100px;
  }

  .title {
    width: 40%;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.35;
    color: #333333;
    @media #{$media-ipad} {
      width: 100%;
      font-size: 24px;
      line-height: 1.2;
    }
  }

  .content {
    padding-top: 20px;
    width: 80%;
    font-size: 16px;
    line-height: 1.8;
    color: #333333;
    @media #{$media-ipad} {
      width: 100%;
      padding-top: 22px;
      font-size: 14px;
      line-height: 1.75;
    }
  }

  .imageContainer {
    margin: 92px auto 0;
    width: 73.75%;
    @media #{$media-ipad} {
      margin-top: 55px;
      width: 100%;
    }

    .image {
      width: 100%;
      padding-top: 45.4449152542%;
      @media #{$media-ipad} {
        padding-top: 52.31958762886598%;
        background-size: contain;
      }
    }
  }
}