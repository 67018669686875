@import "../../../../../../assets/styles/helpers/variables";
.styleContainer {
  background-color: #FCF7EF;
  margin-top: 5vw;
}
.container {
  padding-top: 70px;

  &Content {
    padding: 99px 0;
    position: relative;
    .item {
      @include display-flex;
      &Image {
        width: 40%;
        padding: 0 77px;
        .image {
          width: 100%;
          padding-top: 79.6728971963%;
        }
      }
      &Container {
        width: 60%;
        @media #{$media-ipad} {
          width: 100%;

        }
        @include display-flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
          text-align: left;
        }
        .content {
          padding: 15px 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          text-align: left;
        }
      }
      @media #{$media-ipad} {
        flex-direction: column;

        &Image {
          width: calc(100% - 68px);
          padding: 0 34px;
        }

        &Container {
          width:100%;
          padding: 10px 12px;
        }
      }

    }

    .btn {
      &Next, &Prev {
        position: absolute;
        top: 50%;
        z-index: 500;

        &:hover {
          cursor: pointer;
        }
      }
      &Next {
        right: -5%;
      }
      &Left {
        left: -5%;
      }
      
      @media #{$media-ipad} {
        &Next, &Prev {
          top: 20%;
        }
        &Next {
          right: 0;
        }
      }
    }

    @media #{$media-ipad} {
      padding: 45px 0;
    }
  }
}