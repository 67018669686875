@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 7.62%;
  @media #{$media-ipad} {
    margin-top: 24.77%;
  }

  &Top, &Bottom {
    width: 100%;
  }

  &Bottom {
    @include display-flex;
    justify-content: space-between;
    margin-top: 64px;
    @media #{$media-ipad} {
      flex-direction: column;
      margin-top: 42.5px;
    }
  }

  .sub {
    &Container {
      width: 48.28125%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 20px;
      @media #{$media-ipad} {
        width: 100%;
        margin: 14.5px 0;
      }
    }

    &Title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.25;
      color: #333333;
      @media #{$media-ipad} {
        font-size: 16px;
      }
    }

    &Description {
      padding-top: 15px;
      font-size: 16px;
      line-height: 1.75;
      color: #333333;
      text-align: justify;
      @media #{$media-ipad} {
        font-size: 14px;
      }
    }
  }
}