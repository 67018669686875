@import "../../../../../../assets/styles/helpers/variables";

.container {
  @include display-flex;
  flex-direction: column;
  margin-top: 100px;

  &Top, &Bottom {
    width: 100%;
  }

  &Top {
    @include display-flex;
    flex-direction: column;

    .mainContent {
      width: 70%;
      margin-top: 37px;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      color: #333333;
      @media #{$media-ipad} {
        width: 100%;
        line-height: 27px;
      }
    }
  }

  &Bottom {
    @include display-flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;
    @media #{$media-ipad} {
      flex-direction: column;
      margin-top: 37px;
    }

    .contentContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      @include display-flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px 20px;
      @media #{$media-ipad} {
        padding: 15px 11px;
      }
    }

    .sub {
      &Title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.25;
        color: #FFFFFF;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }

      &Content {
        font-size: 16px;
        line-height: 1.75;
        color: #FFFFFF;
        padding-top: 16px;
        text-align: justify;
        @media #{$media-ipad} {
          font-size: 12px;
          line-height: 1.25;
          padding-top: 10px;
        }
      }
    }

    .item {
      &Container {
        @include display-flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 44.140625%;
        background-image: linear-gradient(180deg, rgba(255, 100, 149, 0.37) 0%, #FF6495 64.6%);
        margin-bottom: 20px;
        @media #{$media-ipad} {
          width: 100% !important;
          margin-bottom: 23px !important;
        }

        .itemImage {
          padding-top: 65.30973451327434%;
        }

        &:nth-child(2n + 1) {
          width: 54.0625%;
          background-image: linear-gradient(180deg, rgba(249, 173, 11, 0.25) 15.12%, #F9AD0B 79.53%);
          margin-bottom: 0;

          .itemImage {
            padding-top: 50.28901734104046%;
          }
        }

        &:nth-child(3n + 1) {
          background-image: linear-gradient(180deg, rgba(62, 149, 252, 0.11) 0%, $main-color 80.23%);
          margin-bottom: 0;

          .itemImage {
            padding-top: 65.30973451327434%;
          }
        }

        &:nth-child(4n + 1) {
          background-image: linear-gradient(180deg, rgba(118, 42, 226, 0.26) 0%, #762AE2 100%);
          margin-bottom: 20px;

          .itemImage {
            padding-top: 50.28901734104046%;
          }
        }
      }

      &Image {
        z-index: -1;
        width: 100%;
        height: 100%;
      }
    }
  }
}