@import "../../../assets/styles/helpers/variables";

.language {
  width: 134px;

  .iconOption {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    @include display-flex;
    flex-direction: column-reverse;

    .iconOptionItem {
      @include display-flex;
      align-items: center;
      padding: 7px 12px;
      background: #FFFFFF;
      font-size: 12px;
      line-height: 1.2;
      color: #999999;
      white-space: nowrap;

      .icon {
        width: 32px;
        min-width: 32px;
        height: 21px;
        z-index: 500;
        background-size: contain;
        margin-right: 12px;
      }

      &:hover {
        transition: all 0.3s;
        cursor: pointer;

        &.iconOptionItem {
          background-color: #F5F5F5;

          .content {
            color: #333333;
          }
        }
      }
    }

    .isActive {
      order: 1;
      color: #333333;

      &.iconOptionItem {
        background-color: #F5F5F5;
      }
    }
  }
}
