@import "../../../../../../assets/styles/helpers/variables";

.marginContainer {
  margin-top: 10.4578%;
  @media #{$media-ipad} {
    margin-top: 157px;
  }
}

.container {
  position: relative;
  @media #{$media-ipad} {
    margin-left: 0;
  }

  .imageContainer {
    width: 37.34375%;
    position: absolute;
    left: 0;
    bottom: 35px;
    @media #{$media-ipad} {
      width: 64.4329896907%;
      left: 50%;
      bottom: auto;
      top: -91px;
      height: fit-content;
      transform: translate(-50%, 0);
    }

    .image {
      width: 100%;
      padding-top: 114.8535564853556%;
    }
  }

  &Content {
    padding: 35px 84px 30px 32.265625%;
    width: 90.78125%;
    background-color: #FCF7EF;
    margin-left: auto;
    @media #{$media-ipad} {
      padding: 237px 20px 38px;
      width: 100%;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    padding-bottom: 15px;
    @media #{$media-ipad} {
      font-size: 16px;
      padding-bottom: 21px;
    }
  }

  .content {
    .paragraph {
      padding: 10px 0;
      font-size: 16px;
      line-height: 1.75;
      color: #333333;
      text-align: justify;
      @media #{$media-ipad} {
        font-size: 14px;
      }
    }
  }
}