@import "../../../../../assets/styles/helpers/variables";

.reachOut {
  width: 100%;
  background: #CCE0FF;
  margin-top: 54px;
  @media #{$media-ipad} {
    margin-top: 136px;
  }

  .content {
    padding: 59px 20px;
    @media #{$media-ipad} {
      padding: 41px 20px;
    }

    .contentHead {
      width: 50%;
      margin-bottom: 61px;
      @media #{$media-ipad} {
        width: 100%;
      }

      .title {
        font-weight: 700;
        font-size: 24px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 23px;
        @media #{$media-ipad} {
          margin: 0;
        }
      }

      .note {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
    }

    .contentForm {
      padding: 10px 13.9%;
      @media #{$media-ipad} {
        padding: 0;
      }

      .form {
        width: 100%;

        .item {
          width: 100%;
          @include display-flex;
          margin-bottom: 20px;
          justify-content: space-between;

          &:focus {
            outline: none;
          }

          select {
            width: 100%;
            padding: 10px;
            border: none;

            &:focus-visible {
              outline: none;
            }
          }

          textarea {
            width: 100%;
            padding: 10px;
            border: none;
            height: 171px;
            font-family: 'Roboto', sans-serif;
            outline: none;
          }

          .itemContainer {
            width: 48.89%;

            &.error {
              input {
                border-color: red;
              }
            }

            input {
              background: #FFFFFF;
              box-sizing: border-box;
              width: 100%;
              font-size: 14px;
              color: #333333;
              padding: 10px;
              border: 1px solid transparent;
              font-family: 'Roboto', sans-serif;

              &::placeholder {
                color: #333333;
                opacity: 1; /* Firefox */
              }

              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #333333;
              }

              &::-ms-input-placeholder { /* Microsoft Edge */
                color: #333333;
              }
            }

            input[type=text]:focus {
              outline: none;
            }
          }
        }

        .action {
          width: 100%;
          margin-top: 39px;
          @include display-flex;
          justify-content: center;
          align-content: center;

          button {
            padding: 16px 28px;
            font-weight: 700;
            font-size: 16px;
            border: none;
            color: #000000;
            background-color: #FFFFFF;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}