@import "../../../../../../assets/styles/helpers/variables";
.container {
  @include display-flex;
  flex-direction: column;
  margin-top: 14.1vh;
  margin-bottom: 16.9vh;
  &Top {
    width: 70%;

    .mainDescription {
      margin-top: 37px;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
    }

    @media #{$media-ipad} {
      width: 100%;
    }
  }

  &Bottom {
    margin-top: 72px;
    @include display-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 46%;
      &Title {
        font-size: 23px;
        font-weight: 700;
        line-height: 33px;
        border-left: 7px solid #3e95fc;
        padding-left: 30px;
        margin-top: 30px;
        text-align: left;
      }
      &Content {
        padding-top: 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
      }
    }

    @media #{$media-ipad} {
      flex-wrap: wrap;
      margin: 0;
      .item {
        width: 100%;
      }
    }
  }
}
