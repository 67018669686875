@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 126px;
  @media #{$media-ipad} {
    margin-top: 85px;
  }

  .main {
    &Description {
      margin-top: 37px;
      font-size: 16px;
      line-height: 1.75;
      text-align: justify;
      @media #{$media-ipad} {
       font-size: 14px;
      }
    }

    &ImageContainer {
      width: 45%;
      margin: 25px auto 0;
      @media #{$media-ipad} {
        width: 100%;
        margin-top: 29px;
      }

      .image {
        width: 100%;
        padding-top: 49.4718309859%;
      }
    }
  }

  &Content {
    margin-top: 78px;
    @media #{$media-ipad} {
      margin-top: 37px;
    }

    .item {
      padding: 20px;
      min-height: 263px;
      @media #{$media-ipad} {
        min-height: 361px;
      }

      &Title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.25;
        color: #333333;
        @media #{$media-ipad} {
          font-size: 16px;
        }
      }

      &Content {
        padding: 15px 0;
        font-size: 14px;
        text-align: justify;
        line-height: 1.75;
        color: #333333;
      }
    }
  }
}