@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 7.62%;
  @media #{$media-ipad} {
    margin-top: 24.77%;
  }

  &Top, &Bottom {
    width: 100%;
  }

  &Bottom {
    @include display-flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 71px;
    padding-top: 19px;
    padding-left: 20px;
    background-image: linear-gradient($sub-color, $sub-color);
    background-size: 8px 100%;
    background-position: left;
    background-repeat: no-repeat;
    @media #{$media-ipad} {
      margin-top: 43px;
      padding-left: 16px;
    }
  }

  .main {
    &Description {
      width: 100%;
      max-width: 906px;
      margin-top: 37px;
      font-size: 16px;
      line-height: 1.75;
      color: #333333;
      @media #{$media-ipad} {
        width: 100%;
        text-align: justify;
      }
    }
  }

  .sub {
    &Container {
      margin-bottom: 57px;
      width: 70%;
      @media #{$media-ipad} {
        width: calc(100% - 17px);
        margin-bottom: 35px;
      }

      &:last-child {
        margin-bottom: 32px;
        @media #{$media-ipad} {
          margin-bottom: 18px;
        }
      }
    }

    &Title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
      color: #FFFFFF;
      padding-left: 30px;
      @media #{$media-ipad} {
        font-size: 16px;
        padding-left: 20px;
      }

      &Container {
        position: relative;
        @include display-flex;
        align-items: center;
        padding: 9px 35px;
        background-color: $main-color;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(62, 149, 252, 0.5);
        @media #{$media-ipad} {
          padding: 18px 36px;
        }

        .icon {
          width: 58px;
          min-width: 58px;
          height: 58px;
          @media #{$media-ipad} {
            width: 40px;
            min-width: 40px;
            height: 40px;
          }
        }

        .connectLine {
          position: absolute;
          height: 1px;
          width: 50px;
          top: 50%;
          bottom: 0;
          left: -20px;
          right: 0;
          z-index: -1;
          border-bottom: 1px $sub-color solid;
          @media #{$media-ipad} {
            left: -16px;
          }
        }
      }
    }

    &Description {
      font-size: 16px;
      line-height: 1.75;
      text-align: justify;
      @media #{$media-ipad} {
        font-size: 14px;
      }
    }
  }
}