@import "../../../../../../assets/styles/helpers/variables";

.container {
  padding-top: 139px;
  @media #{$media-ipad} {
    padding-top: 0;
    margin-top: 20vw;
  }

  &Top, &Bottom {
    width: 100%;
  }

  &Top {
    @include display-flex;
    flex-direction: column;
    padding-bottom: 57px;
    @media #{$media-ipad} {
      padding-bottom: 17.5px;
    }
  }

  &Bottom {
    @include display-flex;
    @media #{$media-ipad} {
      margin: 0;
      flex-direction: column;
    }

    .sub {
      &Container {
        width: 50%;
        @media #{$media-ipad} {
          width: 100%;
          margin: 22.5px 0;
        }
      }

      &Title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: capitalize;
        color: #333333;
        width: 78.90625%;
        @media #{$media-ipad} {
          font-size: 16px;
          width: 100%;
        }
      }

      &Description {
        font-size: 16px;
        font-weight: 400;
        margin-top: 15px;
        line-height: 1.75;
        color: #333333;
        width: 78.90625%;
        text-align: justify;
        @media #{$media-ipad} {
          margin-top: 10px;
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }

  .main {
    &Description {
      width: 100%;
      max-width: 906px;
      margin-top: 37px;
      font-size: 16px;
      line-height: 1.5;
      @media #{$media-ipad} {
        width: 100%;
        text-align: justify;
      }
    }
  }
}