@import "../../../../../assets/styles/helpers/variables";

.container {
  @media #{$media-ipad} {
    padding: 0;
  }

  .header {
    @media #{$media-ipad} {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }

  &OurExpertise {
    background-color: #FCF7EF;
    padding: 60px 0 0;
    margin-top: 106px;
    @media #{$media-ipad} {
      padding: 42px 0 0;
      margin-top: 23.6%;
    }
  }

  &Info {
    background-color: #FFFFFF;
    padding: 30px 25px;
    height: 100%;
    @media #{$media-ipad} {
      padding: 30px;
    }

    .title {
      margin-top: 0;
      font-size: 20px;
      margin-bottom: 25px;
      line-height: 1.15;
      @media #{$media-ipad} {
        margin-bottom: 16px;
        font-size: 16px;
      }
    }

    .content {
      font-size: 16px;
      line-height: 1.75;
      padding-bottom: 18px;
      text-align: justify;
      @media #{$media-ipad} {
        padding-bottom: 20px;
        font-size: 14px;
      }
    }
  }
}

.btn {
  &ReadMore {
    @include display-flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: #DB2017;
    padding: 38px 0;

    img {
      margin: 0 9px;
    }
  }
}