@import "../../../../../../assets/styles/helpers/variables";

.navigation {
  position: fixed;
  top: 60px;
  height: 100%;
  background: #FFFFFF;
  width: 100%;
  padding: 5px 20px;
  z-index: 999;

  &ItemContainer {
    padding-top: 15px;

    h2 {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.25;
      padding-bottom: 6px;
      color: $main-color;
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
      padding: 2px 0;
      text-decoration: underline;
    }
  }

  &ItemWrapper {
    margin: 10px 0;
  }

  &Item {
    width: 100%;
    background: #F5F5F5;
    border-radius: 3px;
    padding: 19px 10px;
    @include display-flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.25;
      color: #000000;
    }

    .icon {
      width: 24px;
      height: 24px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}