@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 170px;
  @media #{$media-ipad} {
    margin-top: 79px;
    padding: 0;
  }

  .titleContainer {
    @media #{$media-ipad} {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }

  .mainDescription {
    margin-top: 46px;
    width: 40%;
    font-size: 16px;
    line-height: 1.75;
    color: #333333;
    @media #{$media-ipad} {
      width: 100%;
      margin-top: 37px;
      padding: 0 20px;
    }
  }

  &Swiper {
    .subText {
      padding: 42px 0;
      font-size: 16px;
      line-height: 1.75;
      @media #{$media-ipad} {
        padding: 37px 20px;
      }
    }

    .item {
      padding: 20px;
      height: 100%;
      color: #FFFFFF;
      @media #{$media-ipad} {
        padding: 20px 18px;
      }

      &Title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.25;
      }

      &Content {
        padding: 20px 0;
        font-size: 16px;
        line-height: 1.75;
      }
    }
  }
}