@import "../../../../../assets/styles/helpers/variables";

.popup {
  width: 100%;
  height: 100vh;
  @include display-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 999;
  @media #{$media-ipad} {
    z-index: 99999;
    align-items: flex-end;
  }

  .popupContainer {
    width: 53.177083333%;
    background: #FFFFFF;
    padding: 15px 24px;
    max-height: 70vh;
    @media #{$media-ipad} {
      width: 100%;
      padding: 15px;
    }

    .popupHeader {
      width: 100%;
      @include display-flex;
      justify-content: flex-end;

      .popupCLose {
        width: 24px;
        height: 24px;
        background-size: contain;
        cursor: pointer;
        @media #{$media-ipad} {
          width: 19px;
          height: 19px;
        }
      }
    }

    .popupBody {
      padding-bottom: 40px;
      @include display-flex;
      height: 100%;
      @media #{$media-ipad} {
        display: block;
        padding: 0 0 30px;
        margin-top: 16px;
        max-height: calc(70vh - 40px);
        overflow-y: auto;
      }

      .image {
        width: 58.78725590955807%;
        height: 100%;
        @media #{$media-ipad} {
          width: 100%;
          padding: 0 5px;
        }
      }

      .info {
        width: 41.21274409044193%;
        padding-left: 4.728%;
        padding-top: 3px;
        @media #{$media-ipad} {
          width: 100%;
          padding-left: 5px;
          padding-right: 5px;
        }

        .projectType {
          font-weight: 700;
          font-size: 16px;
          line-height: 1.25;
          color: #333333;
          padding-top: 12px;
        }

        .projectTitle {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
          color: #333333;
        }

        .projectTechnology {
          font-size: 16px;
          line-height: 1.25;
          @include display-flex;
          align-items: flex-start;
          color: #333333;
          padding: 4px 0;

          .title {
            font-weight: 700;
            font-size: 16px;
            padding-right: 4px;
          }
        }
      }
    }
  }
}

.projectType {
  margin-bottom: 7px;
}