@import "../../../assets/styles/helpers/variables";

.pageChange {
  @include display-flex;
  font-size: 16px;
  margin-top: 23px;
  flex-wrap: wrap;
  @media #{$media-ipad} {
    margin-top: 15px;
  }

  .page {
    color: #999999;

    &:hover {
      cursor: pointer;
    }
  }

  .operator {
    color: #999999;
    margin: 0 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .highlight {
    color: #000000;
  }
}