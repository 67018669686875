@import "../../../assets/styles/helpers/variables";

.container {
  padding-top: 68px;
  width: 100%;
  @media #{$media-ipad} {
    padding-top: 60px;
  }

  &Banner {
    width: 100%;
    padding-top: 19.53125%;
    background-color: $main-color;
    @media #{$media-ipad} {
      padding-top: 48.3644859813%;
    }
  }
}