@import "../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 8vw;
  @media #{$media-ipad} {
    padding: 0;
  }

  &Top {
    width: 40%;

    @media #{$media-ipad} {
      width: 100%;
      padding: 0 20px;
    }
    .mainDescription {
      margin-top: 45px;
      font-size: 16px;
      line-height: 1.8;
      color: #333333;
    }
  }
  &Content {
    margin-top: 48px;

    .item {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      position: relative;
      transition: 0.3s;
      padding: 20px;
      @media #{$media-ipad} {
        background-color: $main-color;
      }

      &Title {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.25;
        text-transform: capitalize;
        color: #333333;
        @media #{$media-ipad} {
          color: #FFFFFF;
          font-size: 16px;
        }
      }

      &Content {
        font-size: 16px;
        line-height: 1.8;
        color: #333333;
        padding-top: 15px;
        padding-bottom: 11px;
        @media #{$media-ipad} {
          color: #FFFFFF;
          padding-top: 19px;
          font-size: 14px;
        }
      }

      img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        @media #{$media-ipad} {
          left: 85%;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .active {
      transition: 0.3s;

      &.item {
        background-color: $main-color;
      }

      .item {
        &Title {
          color: #FFFFFF;
        }

        &Content {
          color: #FFFFFF;
        }
      }
    }
  }


  .btn {
    &ReadMore {
      margin-top: 25px;
      font-weight: 700;
      width: fit-content;
      cursor: pointer;
      @media #{$media-ipad} {
        margin-top: 23px;
      }

      span {
        font-size: 16px;
        line-height: 1.25;
        border-bottom: 1px solid transparent;
        color: $sub-color;
      }

      &:hover {
        span {
          border-bottom: 1px solid $sub-color;
        }
      }

      .icon {
        margin-left: 20px;
        width: 24px;
        height: 24px;
        min-width: 24px;
      }
    }
  }
}