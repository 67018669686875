@import "../../../../../../assets/styles/helpers/variables";

.container {
  @include display-flex;
  flex-direction: column;
  margin-top: 9.6vh;

  &Top, &Bottom {
    width: 100%;
    .mainDescription {
      margin-top: 37px;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
    }
  }

  &Top {
    @include display-flex;
    flex-direction: column;
  }

  &Bottom {
    @include display-flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 71px;
    padding-left: 20px;
    background-image: linear-gradient(#FF6495, #FF6495);
    background-size: 8px 100%;
    background-position: left;
    padding-top: 20px;
    background-repeat: no-repeat;

    .active {
      display: block;
    }
  }

  .main {
    &Description {
      width: 75%;
      margin-top: 37px;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
    }
  }

  .sub {
    &Container {
      width: 70%;

      &:last-child {
        margin-bottom: 20px;
      }

      .activeTitle {
        &.subTitleContainer {
          background-image: linear-gradient(#3E95FC, #3E95FC);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .subTitle {
            color: #FFFFFF;
          }
        }
      }

      .activeContent {
        display: block;
      }

      &:hover {
        cursor: pointer;
      }
      @media #{$media-ipad} {
        width: 100%;
      }
    }

    &Title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
      color: #333333;
      padding-left: 30px;
      @media #{$media-ipad} {
        font-size: 16px;
        padding-left: 25px;
      }

      &Container {
        position: relative;
        @include display-flex;
        align-items: center;
        padding: 9px 0 9px 40px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        img {
          max-width: 48px;
          width: 100%;
          height: auto;
          @media #{$media-ipad} {
            max-width: 33px;
          }
        }

        .connectLine {
          position: absolute;
          height: 1px;
          width: 50px;
          top: 50%;
          bottom: 0;
          left: -2%;
          right: 0;
          z-index: -1;
          border-bottom: 1px #FF6495 solid;
        }
      }
    }

    &Description {
      display: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      padding-bottom: 61px;
    }

  }
}