@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 12vw;

  &Top {
    width: 50%;

    .mainDescription {
      margin-top: 37px;
    }

    @media #{$media-ipad} {
      width: 100%;
    }
  }

  &Bottom {
    margin-top: 47px;
    @include display-flex;
    flex-direction: column;
    align-items: center;

    .tabsContainer {
      @include display-flex;
      justify-content: space-between;

      .tab {
        @include display-flex;
        justify-content: space-between;
        align-items: stretch;
        width: 17.03125%;
        height: 70px;

        img {
          height: auto;
          width: 23.8532110092%;
        }

        &Title {
          padding: 6px 18px;
          background-color: #F5F5F5;
          @include display-flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        }

        &:last-child {
          width: 12.96875%;

          img {
            display: none;
          }
        }

        .tabActive {
          background-color: #CCE0FF;
        }
      }
      @media #{$media-ipad} {
        display: none;
      }
    }

    .longArrow {
      width: 90%;
      height: auto;

      @media #{$media-ipad} {
        display: none;
      }
    }

    .tabsContent {
      width: 32.65625%;
      margin: 42px auto 0;

      .imageContainer {
        width: 90%;
        .image {
          width: 100%;
          padding-top: 60.6936416185%;
        }
      }
      .contentContainer {
        display: none;
        .title {
          padding-top: 27px;
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          text-align: center;
          color: #DB2017;
        }

        .content {
          padding-top: 17px;
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          text-align: center;
        }

        @media #{$media-ipad} {
          display: block;
          margin-top: 94px;
          &:first-child {
            margin-top: 0;
          }
        }
      }

      .active {
        display: block;
      }

      @media #{$media-ipad} {
        width: 100%;
      }
    }
  }
  @media #{$media-ipad} {
    margin-top: 25vw;
  }
}