@import "../../../../../assets/styles/helpers/variables";

.micro {
  @include display-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
  margin-bottom: 150px;
  @media #{$media-ipad} {
    flex-direction: column;
  }

  &Img {
    width: 51.796875%;
    @media #{$media-ipad} {
      margin-top: 42px;
      width: 100%;
      order: 2;
    }

    .img {
      width: 100%;
      padding-top: 50.5279034691%;
      @media #{$media-ipad} {
        padding-top: 50.5154639175%;
      }
    }
  }

  &Content {
    width: 39.296875%;
    @media #{$media-ipad} {
      order: 1;
      width: 100%;
      padding: 0;
    }

    .note {
      font-size: 16px;
      line-height: 1.75;
      color: #333333;
      padding: 7.5px 0;
      text-align: justify;
      @media #{$media-ipad} {
        padding: 10px 0;
        font-size: 14px;
      }
    }

    .title {
      font-weight: 700;
      font-size: 34px;
      line-height: 1.3;
      color: #333333;
      padding-bottom: 12.5px;
      @media #{$media-ipad} {
        font-size: 24px;
        padding-bottom: 12px;
      }
    }
  }
}