@import "../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 8.934%;
  position: relative;
  @media #{$media-ipad} {
    margin-top: 100px;
  }

  &Style {
    width: 100%;
    height: 359px;
    background-color: #FCF7EF;
    position: absolute;
    bottom: 0;
    z-index: -1;
    @media #{$media-ipad} {
      height: 0;
      padding-top: 86.91588785046729%;
    }
  }

  .staffContainer {
    @media #{$media-ipad} {
      padding: 0;
    }
  }

  .itemContainer {
    height: 100%;
    @include display-flex;
    @media #{$media-ipad} {
      flex-direction: column;
    }

    .imageContainer {
      width: 25.625%;
      height: 100%;
      @media #{$media-ipad} {
        width: 50%;
        margin-bottom: 37px;
      }

      .image {
        width: 100%;
        height: 100%;
        border-radius: 5px;

        @media #{$media-ipad} {
          padding-top: 140.2366863905325%;
          height: 0;
        }
      }
    }

    .staffInfoContainer {
      width: 74.375%;
      padding-left: 89px;
      padding-bottom: 51px;
      @media #{$media-ipad} {
        width: 100%;
        padding-left: 0;
        padding-bottom: 94px;
      }

      .staff {
        &Name, &Address {
          font-size: 20px;
          font-weight: 700;
          line-height: 1.25;
          @media #{$media-ipad} {
            font-size: 16px;
          }
        }

        &Name {
          padding-bottom: 10px;
        }

        &Position {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.8;
          padding-bottom: 26px;
          @media #{$media-ipad} {
            font-size: 14px;
          }
        }
      }
    }
  }
}