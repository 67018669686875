@import "../../../../assets/styles/helpers/variables";

.header {
  width: 100%;
  @include display-flex;;
  justify-content: center;
  align-content: center;
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  z-index: 99999;
  @media #{$media-ipad} {
    height: 60px;
  }

  &Container {
    width: 100%;
    @include display-flex;;
    justify-content: space-between;
    align-items: center;
    @media #{$media-ipad} {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .logo {
      width: 174px;
      height: 38px;
      background-size: contain;
      @media #{$media-ipad} {
        width: 78px;
        height: 60px;
        min-width: 78px;
        background-size: 38px 26px;
      }

      &Container {
        width: 51px;
        height: 35px;

        .logoIc {
          width: 100%;
          padding-top: 100%;
          background-size: contain;
          @media #{$media-ipad} {
            width: 100%;
            padding-top: 100%;
          }
        }
      }

      &Content {
        margin-left: 10px;
        @include display-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        color: #0F75BC;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .menu {
      @include display-flex;;
      align-items: center;
      @media #{$media-ipad} {
        height: 100%;
        padding: 0;
      }

      .languageSelectBox {
        @include display-flex;
        align-items: center;
        position: relative;
        padding: 22px 0;
        margin-left: 38px;
        @media #{$media-ipad} {
          margin-left: 0;
          padding: 0;
          height: 100%;
        }

        .iconLanguage {
          width: 32px;
          height: 21px;
          min-width: 32px;
          margin-right: 10px;
        }

        .iconDrop {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }

        .option {
          display: none;
          position: absolute;
          z-index: -1;
          top: calc(100% - 7px);
          left: 50%;
          transform: translate(-50%, 0);
          @media #{$media-ipad} {
            top: 62px;
            right: -65px;
            transform: none;
            left: auto;
          }
        }

        .unhidden {
          display: block;
          z-index: 500;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &Item {
        padding: 23.5px 32px;
        @media #{$media-ipad} {
          display: none;
        }

        &:hover, &.active {
          .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: $main-color;
            border-bottom: 2px $main-color solid;
          }
        }

        &:hover {
          .modal {
            opacity: 1;
            visibility: visible;
            border-top-color: rgba(0, 0, 0, 0.05);
          }
        }

        .modal {
          height: auto;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background: #FFFFFF;
          visibility: hidden;
          opacity: 0;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
          border-top: 1px solid transparent;
          transition: .3s;
          @include display-flex;
          justify-content: center;
          align-content: center;

          .container {
            @include display-flex;
            align-items: flex-start;
            padding-top: 40px;
            padding-bottom: 50px;

            .left {
              width: 50%;
              @include display-flex;
              justify-content: flex-end;

              &Content {
                @include display-flex;
                flex-direction: column;
                width: 50%;

                .subTitle {
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 1.25;
                  color: $main-color;
                  padding-bottom: 6px;
                }

                .item {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 1.5;
                  color: #000000;
                  padding: 2px 0;
                  transition: 0.3s;
                  width: fit-content;

                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    transition: 0.3s;
                  }
                }
              }
            }

            .right {
              @include display-flex;
              align-items: flex-end;
              height: fit-content;
              justify-content: flex-start;
              width: 100%;

              &Container {
                width: 50%;
                @include display-flex;
                justify-content: flex-start;

                .line {
                  border-left: 1px solid $sub-color;
                  width: 3px;
                  box-sizing: border-box;
                  border-right: 1px solid $sub-color;
                  max-height: 150px;
                }
              }

              .fluidContainer {
                @include display-flex;
                width: 100%;
                margin-right: 0;
                margin-left: 79px;

                .itemContainerService {
                  @include display-flex;
                  flex-direction: column;
                  justify-content: space-between;
                  width: 100%;
                  max-width: 150px;
                  margin: 0 9px;
                  cursor: pointer;

                  .itemImage {
                    width: 100%;
                    padding-top: 96.6666666667%;
                  }

                  .itemTitle {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 1.25;
                    text-align: left;
                    padding-top: 12px;
                    margin: 0;
                  }

                  .linkMore {
                    padding: 11px 0 14px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: #DB2017;
                    @include display-flex;
                    align-items: center;

                    img {
                      margin: 0 9px;
                    }
                  }
                }

                .itemImageContainer {
                  width: 100%;
                  padding-top: 52.29681978798587%;
                  height: 0;
                }
              }

              .question {
                font-weight: 700;
                font-size: 14px;
                line-height: 1.25;
                color: $sub-color;
                padding-top: 14px;
                margin: 0;
              }
            }
          }
        }

        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          text-align: start;
          border-bottom: 2px transparent solid;
          transition: .3s;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .bar {
        display: none;
        @media #{$media-ipad} {
          display: block;
          height: 100%;
          width: 68px;
          min-width: 68px;
          background-size: 28px 19px;
        }
      }
    }
  }
}