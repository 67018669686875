@import "../../../../../../../assets/styles/helpers/variables";

.item {
  width: calc(100% / 3 - 40px);
  margin: 27px 20px;
  cursor: pointer;
  @media #{$media-ipad} {
    width: calc(50% - 14px);
    margin: 18px 7px;
  }

  .itemImg {
    width: 100%;
    border-radius: 5px;
    padding-top: 68.137254902%;
  }

  .itemContent {
    margin-top: 13px;

    .itemText {
      @include display-flex;
      align-items: flex-start;
      padding: 4px 0;

      .value {
        color: #333333;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1.25;
        @media #{$media-ipad} {
          font-size: 12px;
        }
      }

      .label {
        white-space: nowrap;
        font-weight: 700;
        font-size: 16px;
        padding-right: 15px;
        text-transform: none;
        line-height: 1.25;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }
    }

    .website {
      margin-top: 8px;
      font-size: 16px;
      color: #333333;
      @media #{$media-ipad} {
        font-size: 14px;
      }

      span {
        font-weight: 700;
        font-size: 14px;
        @media #{$media-ipad} {
          font-size: 12px;
        }
      }
    }
  }
}