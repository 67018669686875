@import "../../../../../../../assets/styles/helpers/variables";

.styleContainer {
  background-color: #CCE0FF;
  margin-top: 6.727%;

  .container {
    padding-top: 26px;
    @media #{$media-ipad} {
      padding: 38px 0 77px;
    }

    .title {
      @media #{$media-ipad} {
        margin: 0 20px;
        width: calc(100% - 40px);
      }
    }

    &Content {
      padding: 50px 0 101px;
      @media #{$media-ipad} {
        padding-top: 36px;
        padding-bottom: 0;
      }

      .item {
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
        padding: 15px;

        &Title {
          @include display-flex;
          align-items: center;
          font-size: 20px;
          line-height: 1.2;
          text-transform: capitalize;
          color: #7879F1;
          border-bottom: 2px solid #7879F1;
          padding-bottom: 15px;

          .icon {
            width: 42px;
            min-width: 42px;
            height: 42px;
            margin-right: 20px;
          }
        }

        &Content {
          padding-top: 20px;
          font-size: 16px;
          line-height: 1.8;
          color: #333333;
        }
      }
    }
  }
}