@import "../../../../../../assets/styles/helpers/variables";

.container {
  background: #3e95fc;
  border-radius: 5px;
  padding: 35px;
  margin-top: 9.6vh;
  position: relative;
  .main {
    &Title {
      width: 70%;
      text-transform: uppercase;
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: 0;
      text-align: left;
      color: #ffffff;
      padding-bottom: 20px;
      background-image: linear-gradient(#ffffff, #ffffff);
      background-size: 120px 5px;
      background-position: bottom left;
      background-repeat: no-repeat;
      @media #{$media-ipad} {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
      }
    }

    &Content {
      width: 70%;
      padding-top: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
      color: #ffffff;
    }

    @media #{$media-ipad} {
      &Title {
        width: 50%;
      }
      &Content {
        width: 100%;
      }
    }
  }

  .imageList {
    width: 70%;
    @include display-flex;
    margin: 47px 0 150px;
    gap: 50px 31px;
    flex-wrap: wrap;
    .imageContainer {
      width: 80px;
      background-color: #ffffff;
      padding: 6px;
      position: relative;

      .image {
        width: 100%;
        padding-top: 100%;
      }

      span {
        position: absolute;
        bottom: -30%;
        left: 25%;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        text-align: left;
        color: #ffffff;
      }
    }

    @media #{$media-ipad} {
      width: 100%;
      margin-bottom: 55px;
      .imageContainer {
        margin-right: 16px;

        span {
          font-weight: 400;
          left: 10%;
          bottom: -50%;
        }
      }
    }
  }
  .rightImage {
    width: 30%;
    position: absolute;
    top: -5%;
    right: -1%;
    .mainImage {
      width: 100%;
      padding-top: 130.521091811%;
    }
    @media #{$media-ipad} {
      display: none;
    }
  }
  @media #{$media-ipad} {
    padding: 20px;
  }
}
