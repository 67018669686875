@import "../../../../assets/styles/helpers/variables";

.styleContainer {
  background-color: #FCF7EF;
  padding: 62px 0 49px;
  margin-top: 24.77%;
}

.container {
  &Top, &Bottom {
    width: 100%;
  }

  &Bottom {
    @include display-flex;
    justify-content: space-between;
    margin-top: 73px;

    &Left {
      width: 100%;
      @include display-flex;
      flex-direction: column;
      padding-left: 5px;
      background-image: linear-gradient($sub-color, $sub-color);
      background-size: 5px 100%;
      background-position: left;
      background-repeat: no-repeat;

      .tab {
        padding: 22px 20px;
        position: relative;

        &:hover {
          cursor: pointer;
          transition: all 0.3s;
        }

        .connectLine {
          position: absolute;
          height: 1px;
          width: 19px;
          top: 50%;
          bottom: 0;
          left: -1%;
          right: 0;
          z-index: 1;
          border-bottom: 1px $sub-color solid;
        }

        &Title {
          padding-left: 7px;
          font-size: 20px;
        }
      }

      .activeTab {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.25;
        color: $main-color;

        .connectLine {
          border-bottom: 3px $sub-color solid;
        }
      }

      .tabLinks {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.25;
        color: #999999;
      }

      .contentContainer {
        display: none;
        padding: 0 20px 53px;

        &.active {
          display: block;
        }

        .content {
          font-size: 14px;
          line-height: 1.8;
          padding-left: 7px;
        }

        .imageContainer {
          width: 100%;
          max-width: 230px;
          margin: 46px auto 0;

          .image {
            width: 100%;
            padding-top: 73.0434782609%;
          }
        }
      }
    }
  }
}