@import "../../../assets/styles/helpers/variables";

.container {
  margin-top: 30px;
  color: #000000;
  .introduce {
    font-size: 18px;
    line-height: 23px;
    white-space: pre-line;
    margin-bottom: 15px;
  }
  .image {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 24px;
    @media #{$media-ipad} {
      width: 80%;
    }
  }
  .title {
    font-size: 30px;
    line-height: 36px;
    margin: 20px 0;
    padding-left: 30px;
    border-left: 6px solid #3e95fc;
    @media #{$media-ipad} {
      font-size: 22px;
      line-height: 26px;
    }
    &Advantage {
      margin-bottom: 10px;
      font-size: 26px;
      line-height: 30px;
      font-weight: 600;
      @media #{$media-ipad} {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
  .list {
    font-size: 18px;
    line-height: 23px;
    padding-left: 20px;
    color: #3e95fc;
    margin-bottom: 15px;
    &Item {
      color: #000000;
      margin-bottom: 15px;
    }
  }
}
