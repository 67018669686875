@import "../../../../../assets/styles/helpers/variables";
.address{
  @include display-flex;
  margin-top: 129px;
  .addressMap{
    width: 50%;
    height: auto;
    .map {
      width: 100%;
      height: 100%;
    }
  }
  .addressInfo{
    width: 50%;
    padding: 61px 5%;
    .content{
      width: 100%;
      h2{
        font-weight: 700;
        font-size: 34px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 30px;
      }
      p{
        font-size: 16px;
        margin-bottom: 15px;
        color: #333333;
        display: flex;
        .title{
          font-weight: 700;
          font-size: 20px;
          white-space: nowrap;
        }
        .note{
          margin-left: 20px;
          margin-top: 3px;
        }
      }
    }
  }

  @media #{$media-ipad} {
    flex-direction: column;
    margin-top: 28px;
    .addressMap {
      width: 100%;
      height: 236px;
    }
    .addressInfo {
      margin-top: 28px;
      width: 100%;
      padding: 0;
      .content {
        width: 100%;
        h2 {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 22px;
        }
        p {
          font-size: 14px;
          margin-top: 7px;
          .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
          }
        }
      }
    }
  }
}