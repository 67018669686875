@import "../../../../../../../assets/styles/helpers/variables";

.styleContainer {
  background-color: #FCF7EF;
  margin-top: 88px;
  @media #{$media-ipad} {
    margin-top: 43px;
  }
}

.container {
  padding-top: 70px;
  @media #{$media-ipad} {
    padding-top: 50px;
  }

  .mainDescription {
    min-height: 40px;
    margin-top: 19px;
    @media #{$media-ipad} {
      margin-top: 15px;
    }
  }

  &Content {
    padding: 40px 0 104px;
    position: relative;
    @media #{$media-ipad} {
      padding: 35px 0 70px;
    }

    .item {
      @include display-flex;
      align-items: center;
      @media #{$media-ipad} {
        flex-direction: column;
      }

      &Image {
        width: 37.34729493891798%;
        @media #{$media-ipad} {
          width: 100%;
          padding: 0;
        }

        .image {
          width: 100%;
          padding-top: 79.6728971963%;
        }
      }

      &Container {
        width: 62.65270506108202%;
        @include display-flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 6.72%;
        @media #{$media-ipad} {
          width: 100%;
          padding-left: 0;
          padding-top: 28px;
        }

        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2;
          @media #{$media-ipad} {
            font-size: 20px;
          }
        }

        .content {
          padding-top: 15px;
          font-size: 16px;
          line-height: 1.8;
          text-align: justify;
          @media #{$media-ipad} {
            font-size: 14px;
          }
        }
      }
    }
  }
}