@import "../../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 5vw;
  @media #{$media-ipad} {
    margin-top: 12vh;

  }

  &Content {
    @include display-flex;
    flex-direction: column;

    .item {
      margin-top: 152px;
      width: 75%;
      height: 370px;
      @include display-flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      right: -25%;

      &:nth-child(even) {
        align-items: flex-start;
        right: 0;
        .imageContainer {
          right: -33%;
          left: unset;
        }
      }

      &:nth-child(1) {
        background-color: #FCF7EF;
      }

      &:nth-child(2) {
        background-color: #A5A6F6;
      }

      &:nth-child(3) {
        background-color: #FFCCDD;
      }

      &:nth-child(4) {
        background-color: #3E95FC;
      }

      .title {
        width: 70%;
        padding: 35px 35px 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
      }

      .content {
        width: 70%;
        padding: 25px 35px 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
      }
      .imageContainer {
        width: 56.0123329908%;
        position: absolute;
        top: -20%;
        left: -33%;
        .image {
          width: 100%;
          padding-top: 75.0458715596%;
        }
      }

      @media #{$media-ipad} {
        width: 100%;
        left: 0;
        height: auto;
        margin-top: 127px;
        .imageContainer {
          width: 61.2745098039%;
          left: 20%;
          top: -10%
        }
        &:nth-child(even) {
          color: #FFFFFF;
          .imageContainer {
            right: 20%;
          }
        }

        .title {
          width: calc(100% - 40px);
          padding: 150px 20px 0;
          font-size: 16px;
          line-height: 19px;
        }

        .content {
          width: calc(100% - 40px);
          padding: 12px 20px 30px;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
}