@import "../../../../../assets/styles/helpers/variables";

.container {
  margin-top: 8.934%;
  @include display-flex;
  justify-content: space-between;
  @media #{$media-ipad} {
    margin-top: 98px;
    flex-direction: column;
  }

  &Left {
    width: 40.859375%;
    @media #{$media-ipad} {
      width: 100%;
    }

    .mainDescription {
      @media #{$media-ipad} {
        padding: 0;
        margin: 0;
      }
      p {
        color: #333333;
        padding-top: 38px;
        font-size: 16px;
        line-height: 1.8;
        @media #{$media-ipad} {
          padding-top: 45px;
        }
      }
    }
  }

  &Right {
    width: 48.125%;
    @media #{$media-ipad} {
      padding-top: 59px;
      width: 100%;
    }

    .item {
      background-color: $main-color;
      margin-bottom: 11px;
      padding: 5px 15px;
      @include display-flex;
      align-items: center;
      @media #{$media-ipad} {
        margin-bottom: 17px;
      }

      .icon {
        width: 35px;
        min-width: 35px;
        height: 35px;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.25;
        padding-left: 8px;
        color: #FFFFFF;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }
    }
  }
}